var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-form-group",
    { attrs: { invalid: _vm.error !== null } },
    [
      _c(
        "gov-radios",
        [
          _c(
            "gov-label",
            { staticClass: "govuk-!-font-weight-bold", attrs: { for: _vm.id } },
            [_vm._t("label", [_vm._v(_vm._s(_vm.label))])],
            2
          ),
          _vm._t("hint", [
            _vm.hint
              ? _c("gov-hint", {
                  attrs: { for: _vm.id },
                  domProps: { textContent: _vm._s(_vm.hint) }
                })
              : _vm._e()
          ]),
          _vm._l(_vm.options, function(option, index) {
            return _c("gov-radio", {
              key: index,
              attrs: {
                "bind-to": _vm.value,
                id: _vm.id + "_" + index,
                name: _vm.id,
                label: option.label,
                value: option.value,
                disabled: _vm.disabled
              },
              on: {
                input: function($event) {
                  return _vm.$emit("input", $event)
                }
              }
            })
          }),
          _vm._t("after-input"),
          _vm.error !== null
            ? _c("gov-error-message", {
                attrs: { for: _vm.id },
                domProps: { textContent: _vm._s(_vm.error) }
              })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }